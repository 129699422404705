﻿import { LocalStorageProperty } from "./Scripts/LocalStorageProperty";
import { scrollToFirstMatch } from "./Scripts/ScrollToFirstMatch";
import { PatternInputMask } from "./Scripts/PatternInputMask";
import { watchActivity } from "./Scripts/ActivityWatcher";


var culture = LocalStorageProperty('culture');
var jwt = LocalStorageProperty('jwt');
var username = LocalStorageProperty('username');
var forceLanguage = LocalStorageProperty('forceLanguage');

function createPatternMask(elementId, interopObject, pattern) {
    return new PatternInputMask(elementId, interopObject, pattern);
}

function createLocalStorageProperty(name) {
    return LocalStorageProperty(name);
}

export {
    culture,
    jwt,
    username,
    forceLanguage,
    scrollToFirstMatch,
    createPatternMask,
    createLocalStorageProperty,
    watchActivity
}