function ActivityWatcher(elementReferece) {
	var el = elementReferece[0];

	var canAdd = true;
	var lastTime = new Date();

	function maybeUpdateLastTime() {

		if (!canAdd) {
			return;
		}

		lastTime = new Date();
		canAdd = false;

		setTimeout(function () {
			canAdd = true;
		}, 500);
	}	

	function timeSinceLastUpdateMS() {
		var now = new Date();
		var result = now.getTime() - lastTime.getTime();
		return result;
	}

	function stop() {
		el?.removeEventListener('mousemove', maybeUpdateLastTime);
	}

	el.addEventListener('mousemove', maybeUpdateLastTime);

	return {
		timeSinceLastUpdateMS,
		stop,
		maybeUpdateLastTime
	}
}

export function watchActivity(element: HTMLElement) {

	var watcher = ActivityWatcher(element);
	var clientWindow = (window as any);
	var elementId = element[0].id;

	if (elementId !== null && clientWindow.addWatcher) {
		clientWindow.addWatcher(elementId, watcher);
	}
	
	return watcher;
}